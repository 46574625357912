import React from 'react';
import {Paper, Typography} from '@material-ui/core'
import ProfilePic from '../Assets/images/profile-picture.jpg'

const Home = (props) => {
    return (
        <div id={props.id}>
            <Paper elevation={3} style={{margin:'auto', marginTop: 20, width:'75%'}}>
                <div className='row' style={{margin:'auto',backgroundColor:'#f6cc3e'}}>
                <img src={ProfilePic} height='400px' alt='profile-pic'/>
                <div style={{margin:'auto'}}>
                <Typography variant='h3'>
                    Hi, I'm
                </Typography>
                <Typography variant='h2' color='secondary'>
                    Ishita
                </Typography>
                </div>
                </div>
            </Paper>
        </div>
    )
}

export default Home;