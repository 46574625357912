import React from 'react';
import { Typography, Paper, Divider } from '@material-ui/core';

const About = (props) => {
    return (
        <div id={props.id}>
            <Paper elevation={3} style={{margin:'auto', marginTop: 20, width:'75%', padding:20}}>
            <Typography color='secondary' variant='h4'>
                About Me
            </Typography>
            <Divider/>
            <div>
                    <Typography variant='body1'>
                    It's been 2+ years of experience in programming and I have enjoyed every moment of it!
                    I have explored various programming languages till now and experimented with different
                    frameworks. However, the beauty of programming is that there is always so much to learn
                    and that gives me the opportunity to be a quick learner.
                    </Typography>
                    <Typography variant='body1'>
                    I have a proven track record of being quick on my feet to display logical thinking and 
                    problem-solving skills. In addition, I am always excited to work in a team which offers
                    amazing opportunities to learn and collaborate and, ultimately, deliver high quality of 
                    work.
                    </Typography>
                <Divider />
                <br/>
                <div>
                    <Typography variant='h6' color='secondary'>
                        Work Experience
                    </Typography>
                    <Typography variant='body2' style={{color:'#89979f'}}>
                        November 2019 - Present
                    </Typography>
                    <Typography variant='body1'>
                        <b style={{color:'#f5434e'}}>Software Developer</b><span>, Life Skills Group Online, Australia</span>
                    </Typography>
                    <ul>
                        <li>Developing, maintaining and testing web-based Social-Emotional Learning Platform for K-6.</li>
                        <li>Frontend programming in ReactJS framework, Responsive Web designing, UI/UX using HTML5,CSS3 and SASS.</li>
                        <li>Integrating and working with Redux for application state management.</li>
                        <li>Backend programming and developing RESTful APIs in NodeJS.</li>
                        <li>Developing, maintaining and testing database system using Sequelize ORM for MySQL.</li>
                        <li>Integrating with Redis and automating storing, retrieving, maintaining and destroying cache data.</li>
                        <li>Version controlling with Git and CI/CD on Gitlab.</li>
                        <li>Uploading and maintain data in Amazon S3.</li>
                        <li>Developing platform for purchasing products and integrating with Stripe API and Webhooks for payments.</li>
                        <li>Automating testing with Cypress.</li>
                        <li>Integrating websockets in the platforms with Socket.io and built system for live reporting between platforms and live notification system between server and client.</li>
                        <li>Developed desktop GUI for platforms using ElectronJs.</li>
                        <li>Developing ERD for database systems using Draw.io and wireframes for platform features using AdobeXD.</li>
                        <li>Working in an agile software development environment with 2 week long sprints, maintaining and creating tasks in Asana and JIRA softwares.</li>
                    </ul>
                    <br/>
                    <Typography variant='body2' style={{color:'#89979f'}}>
                        June 2018 - December 2018
                    </Typography>
                    <Typography variant='body1'>
                        <b style={{color:'#f5434e'}}>Retainer, Online Assessment Team</b><span>, Pearson India Education Services Pvt. Ltd., India</span>
                    </Typography>
                    <ul>
                        <li>Building score formula for adaptive logic for Pearson MyInsights and testing using programming language C.</li>
                        <li>Worked on API integration of a third-party mobile app with Pearson Product.</li>
                        <li>Running tests to check functionality of Pearson MyInsights, coordinating with vendors for checking and removal of bugs in the adaptive logic and assessments.</li>
                        <li>Developing and reviewing assessments for grade 11-12 of Physics.</li>
                        <li>Coordinating with vendors for content development and ensuring quality checks.</li>
                    </ul>
                    <br/>
                    <Typography variant='body2' style={{color:'#89979f'}}>
                        August 2016 - May 2016
                    </Typography>
                    <Typography variant='body1'>
                        <b style={{color:'#f5434e'}}>Software Developer</b><span>, Visual Softech, India</span>
                    </Typography>
                    <ul>
                        <li>Developing web-based and windows-based applications in .NET framework and SQL Server for database systems.</li>
                        <li>Working with team in quality assurance capacity to support high paced development.</li>
                        <li>Providing customer support for maintaining and developing software.</li>
                        <li>Researching and analyzing information to determine, recommend and plan projects for clients.</li>
                    </ul>
                </div>
                <Divider />
                <br/>
                <div>
                    <Typography variant='h6' color='secondary'>
                        Skills
                    </Typography>
                    <ul>
                        <li>
                            Programming Languages
                            <ul>
                                <li>Proficient - JavaScript, C#</li>
                                <li>Familiar - C, C++, Python</li>
                            </ul>
                        </li>
                        <li>
                            Frameworks
                            <ul>
                                <li>ReactJS</li>
                                <li>Express for NodeJS</li>
                                <li>ASP.NET MVC</li>
                            </ul>
                        </li>
                        <li>
                            IDE
                            <ul>
                                <li>Visual Studio Code</li>
                                <li>Visual Studio 2019</li>
                                <li>PyCharm</li>
                            </ul>
                        </li>
                        <li>
                            Database Systems
                            <ul>
                                <li>ORM - Sequelize</li>
                                <li>MySQL</li>
                            </ul>
                        </li>
                        <li>
                            Cloud Computing Services
                            <ul>
                                <li>Amazon Web Services (AWS)</li>
                            </ul>
                        </li>
                        <li>
                            Project Management Information Softwares
                            <ul>
                                <li>Asana</li>
                                <li>JIRA</li>
                            </ul>
                        </li>
                        <li>
                            Soft Skills
                            <ul>
                                <li>Hardworking, flexible and willingness to learn.</li>
                                <li>Ability to work in Agile environment and adapt quickly.</li>
                                <li>Well-developed communication skills</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Divider />
                <br/>
                <div>
                    <Typography variant='h6' color='secondary'>
                        Education
                    </Typography>
                    <Typography variant='body2' style={{color:'#89979f'}}>
                        March 2020 - Present
                    </Typography>
                    <Typography variant='body1'>
                        <b style={{color:'#f5434e'}}>Master of Applied Information Technology</b><span>, Victoria University, Sydney, Australia</span>
                    </Typography>
                    <Typography variant='body2' style={{color:'#89979f'}}>
                        July 2013 - June 2016
                    </Typography>
                    <Typography variant='body1'>
                        <b style={{color:'#f5434e'}}>Bachelor (Honours) of Science in Physics</b><span>, Delhi University, Delhi, India</span>
                    </Typography>
                </div>
                <Divider />
                <br/>
                <div>
                    <Typography variant='h6' color='secondary'>
                        Hobbies
                    </Typography>
                    <Typography>
                        Swimming ◉ Travelling ◉ Reading
                    </Typography>
                </div>
            </div>
        </Paper>
        </div>
    )
}

export default About;