import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import EmailSharpIcon from '@material-ui/icons/EmailSharp';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import './MainLayout.css'

const Footer = () => {
    return (
        <>
            <div className='footer row' style={{backgroundColor:'#3f51b5',padding:'1%',color:'white',marginLeft:0,marginTop:5}}>
                <div className='col-3'>
                    <Typography style={{paddingTop:10}}>
                        Copyright© 2021 Ishita Vig All Rights Reserved.
                    </Typography>
                </div>
                <div className='col-3'>
                <Typography>
                    <IconButton color='inherit'>
                        <CallIcon />
                    </IconButton>
                    (+61)429183939
                </Typography>
                </div>
                <div className='col-4'>
               <Typography>
                    <IconButton color='inherit'>
                        <EmailSharpIcon />
                    </IconButton>
                    ishitavig16@gmail.com
                </Typography>
                </div>
               <div className='col-2'>
                    <IconButton color='inherit' aria-label='linkedin' onClick={() => window.open('https://www.linkedin.com/in/ishita-vig-604205a8/?originalSubdomain=au')}>
                        <LinkedInIcon />
                    </IconButton>
               </div>
            </div>        
        </>
    )
}

export default Footer;