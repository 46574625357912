import React, {useState} from 'react';
import axios from 'axios';
import { Button, Paper, TextField, Typography, Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Contact = (props) => {
    const [query, setQuery] = useState({name:'', mobile: '', email: '', message: ''})
    const [open, setOpen] = useState(false);

    const sendQuery = async (data) => {
        const result = await axios.post(`https://personal-webserver.herokuapp.com/api/mail`,data);

        if(+result.status === 200) {
            setOpen(true);
            setQuery({name:'', mobile: '', email: '', message: ''})
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    return (
        <div id={props.id}>
            <Paper elevation={3} style={{margin:'auto', marginTop: 20, width:'75%'}}>
                <div style={{textAlign:'center', paddingTop:20}}>
                <Typography variant='h4' color='primary'>
                    Drop me a message!
                </Typography>  
                </div>
                <div className='row' style={{textAlign:'center', justifyItems:'center', paddingTop:20}}>
                        <div className='col-6' style={{margin:'auto'}}>
                        <form>
                            <TextField id='name' label='Name' style={{width:'30vw'}} onChange={(e) => {setQuery({...query, name: e.target.value})}} value={query.name}/>
                            <TextField id='mobile' label='Mobile' style={{width:'30vw'}} onChange={(e) => {setQuery({...query, mobile: e.target.value})}} value={query.mobile}/>
                            <TextField id='email' label='Email' style={{width:'30vw'}} onChange={(e) => {setQuery({...query, email: e.target.value})}} value={query.email}/>
                        </form>
                        </div>
                        <div className='col-6' style={{margin:'auto'}} >
                            <form>
                                <input 
                                    type='text' 
                                    style={{minWidth:'30vw', minHeight:'10vw', border:'2px dashed black', textAlign:'center'}} 
                                    placeholder='Message...'  
                                    onChange={(e) => {setQuery({...query, message: e.target.value})}}
                                    value={query.message}
                                />
                            </form>
                        </div>
                </div>
                <div style={{textAlign:'center', padding:20}}> 
                <Button variant='contained' color='secondary' onClick={() => sendQuery(query)}>
                    Send
                </Button>
                <div>
                <Snackbar
                    anchorOrigin={{
                        vertical:'top',
                        horizontal:'right'
                    }}
                    open={open}
                    autoHideDuration={6000}
                    message='Your message was sent successfully!'
                    onClose={handleClose}
                    action={
                        <React.Fragment>
                          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </React.Fragment>
                      }
                />
                </div>
                </div>
            </Paper>
        </div>
    )
}

export default Contact;