import React from 'react';
import { Paper, Typography, Divider } from '@material-ui/core';

const Portfolio = (props) => {
    return (
        <div id={props.id}>
            <Paper elevation={3} style={{margin:'auto', marginTop: 20, width:'75%', padding:20}}>
            <Typography color='secondary' variant='h4'>
                Portfolio
            </Typography>
            <Divider/>
            <br/>
            <div>
                <ul>
                    <li>
                        <a href='https://github.com/ishitavig/usermodelling' target='_blank' rel="noreferrer">User Modelling Inc. (website repo)</a>
                    </li>
                    <li>
                        <a href='https://pinmynotes.vercel.app' target='_blank' rel="noreferrer">Pin My Notes</a>
                        <ul>
                            <li>
                                <a href='https://github.com/ishitavig/pin-my-notes' target='_blank' rel="noreferrer">Public git repo for Pin My Notes</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            </Paper>
        </div>
    )
}

export default Portfolio;