import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Home from './Home';
import Contact from './Contact';
import About from './About';
import Portfolio from './Portfolio';
import Blogs from './Blogs';

const MainLayout = () => {
    const [selectedSection, setSelectedSection] = useState('');

    useEffect(() => {
        const el = document.getElementById(selectedSection);
        if(el !== null) {
            el.scrollIntoView({behavior:'smooth'});
            setSelectedSection('');
        }
    },[selectedSection]);

    return (
        <div>
            <Header selected={(section) => setSelectedSection(section)}/>
            <Home id='home'/>
            <About id='about'/>
            <Portfolio id='portfolio'/>
            <Blogs id='blogs'/>
            <Contact id='contact'/>
            <Footer />
        </div>
    )
}

export default MainLayout;