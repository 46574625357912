import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button } from '@material-ui/core';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import './MainLayout.css'

const Header = (props) => {
    return (
        <>
            <AppBar position='static'>
                <Toolbar>
                    <IconButton edge="start" className='menuButton' color="inherit" aria-label="menu"  onClick={() => {props.selected('home')}}>
                        <DeveloperModeIcon />
                    </IconButton>
                    <Typography className='title' onClick={() => {props.selected('home')}} style={{cursor:'pointer'}}>
                        Ishita Vig
                    </Typography>
                    <Button color='inherit' style={{marginRight:'2vw'}} onClick={() => {props.selected('home')}}>
                        Home
                    </Button><Button color='inherit' style={{marginRight:'2vw'}} onClick={() => {props.selected('about')}}>
                        About Me
                    </Button>
                    <Button color='inherit' style={{marginRight:'2vw'}} onClick={() => {props.selected('portfolio')}}>
                        Portfolio
                    </Button>
                    <Button color='inherit' style={{marginRight:'2vw'}} onClick={() => {props.selected('blogs')}}>
                        Blogs
                    </Button>
                    <Button color='inherit' style={{marginRight:'2vw'}} onClick={() => {props.selected('contact')}}>
                        Contact
                    </Button>
                </Toolbar>
            </AppBar>        
        </>
    )
}

export default Header;