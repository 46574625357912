import React from 'react';
import { Paper, Typography, Divider } from '@material-ui/core';

const Blogs = (props) => {
    return (
        <div id={props.id}>
            <Paper elevation={3} style={{margin:'auto', marginTop: 20, width:'75%', padding:20}}>
            <Typography color='secondary' variant='h4'>
                Blogs
            </Typography>
            <Divider/>
            <Typography variant='h6' color='primary'>
                Comming soon!
            </Typography>
            </Paper>
        </div>
    )
}

export default Blogs;